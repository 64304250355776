import { useEffect, useState, useMemo, Fragment } from "react";
import { get } from "api/request";
import config from "config";
import axios from "axios";
import { CircularProgress, Grid } from "@newulife/common";
import SiteNavBar from "components/navbars/SiteNavBar";
import PhoneVerification from "./PhoneVerification";
import RealNameVerification from "./RealNameVerification";
import TermsAndConditions from "./TermsAndConditions";
import {
  Paper,
  Stack,
  Typography,
  Button,
  Divider,
  Container,
  Box,
  Alert,
} from "@newulife/common";
import { useContentfulContext, ContentfulProvider, useContentful } from "hooks/useContentful";
import { useLocation } from "react-router-dom";
import { useApp } from "contexts/AppContext";
import { languageApi } from 'api/language'
import { useRecoilState } from "recoil";
import { devToolsState } from "state";
import { mockApiCall } from "helpers/mockApiCall";
import { RichText } from "modules/RichText";
import { CONTENTFUL } from "constants/contentful";

const SECTIONS = {
  IsPhoneValidated: {
    title: "Phone Verification",
    component: PhoneVerification,
    validation: [(fields) => !!fields.phone?.length],
  },
  IsNameAndIDValidated: {
    title: "Real Name Verification",
    component: RealNameVerification,
    validation: [
      (fields) => fields.realName?.length,
      (fields) => fields.residentRegistrationNumber?.length,
      (fields) => fields.krIdStatus === "Valid",
    ],
  },
  checkterms: {
    title: "Terms and Conditions",
    component: TermsAndConditions,
    validation: [
      (fields) =>
        fields.terms?.every((term) =>
          term?.MiscField ? true : term?.IsAgreedTo
        ),
    ],
  },
};

const Verification = ({marketingOptInContent}) => {
  const { title, description, inputModelArray } = useContentfulContext({
    entryPoint: "fields",
  });
  const {
    state: { rep },
  } = useApp();
  const { search } = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(search.replace(/&amp;/g, "&")),
    [search]
  );
  const ARTemplateID = searchParams.get("artemplateid");
  const [form, setForm] = useState({});
  const [saveApiError, setSaveApiError] = useState(false);
  const [saveApiIsLoading, setSaveApiIsLoading] = useState(false);
  const [saveApiErrorCode, setSaveApiErrorCode] = useState(false);
  const [devToolsSettings, setDevToolsSettings] = useRecoilState(devToolsState);
  const isKorea = rep?.BillCountry === "KOREA (THE REPUBLIC OF)";
  
  const sections = useMemo(() => {
    const fieldsList =
      searchParams
        .get("fields")
        ?.split("|")
        .filter((field) => field.trim() !== "") || [];

    return Object.keys(SECTIONS).reduce((acc, key) => {
      if (
        fieldsList.includes(key) ||
        (key === "checkterms" && searchParams.has("checkterms"))
      ) {
        acc.push(SECTIONS[key]);
      }
      return acc;
    }, []);
  }, [searchParams]);

  const isVerified = sections.every((o) => o.validation?.every((v) => v(form)));

  const addToForm = (data = {}, miscFields = {}) => {
    setForm((prev) => ({
      ...prev,
      ...data,
      miscFields: {
        ...prev.miscFields,
        ...miscFields,
      },
    }));
  };

  const saveValidation = async () => {
    setSaveApiIsLoading(true)
    // Remove unnecessary properties from form state
    const propertiesToOmit = ["realName", "residentRegistrationNumber"]
    const cleanFormData = Object.entries(form).reduce((acc, [key, value]) => {
      if (!propertiesToOmit.includes(key)) {
        acc[key] = value
      }
      return acc
    }, {})

    //devTools Values for Error
    const devToolsError =
      devToolsSettings.disableVerificationRedirect &&
      devToolsSettings.verificationSubmissionResult === "error"

    // submit the data
    try {
      let res = {};
      if (devToolsError) {
        res = await mockApiCall("post-autoresponder-details")
      } else {
        res = await axios.post(
          `${config.REACT_APP_API_URL}/api/profile/autoresponder-details`,
          {
            ...cleanFormData,
            ARTemplateID,
          },
          {
            headers: {
              Authorization: `Bearer ${rep?.token}`,
            },
          }
        )
        if (!devToolsSettings.disableVerificationRedirect) {
          if (res.data?.url) {
            setSaveApiError(false)
            setSaveApiIsLoading(false)
          }  
          window.location.replace(res.data?.url);
        }
      }
    } catch (error) {
      setSaveApiError(saveApiError + 1)
      setSaveApiErrorCode(error.response.data.id)
      setSaveApiIsLoading(false)
      console.error(
        "Error during the save validation:",
        error.response ? error.response.data.message : error.response.data
      );
    }
  };
  // if (!fields?.length) return null

  return (
    <div>
      <Container>
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            maxWidth: "700px",
            mx: "auto",
          }}
        >
          <Stack
            display="grid"
            p={{
              xs: 2,
              sm: 3,
            }}
            gap={4}
          >
            <Stack
              display="grid"
              gap={3}
              justifyContent="center"
              justifyItems="center"
              justifySelf="center"
              textAlign="center"
              maxWidth="500px"
            >
              <Typography
                variant="header1"
                fontWeight="400"
                fontSize="24px"
                lineHeight="28px"
                color="neutralCool.dark"
              >
                {isKorea ? title : marketingOptInContent?.fields?.optionalSectionText}
              </Typography>
              {isKorea && <Typography variant="body3">{description}</Typography>}
            </Stack>
            <Box
              sx={{
                display: "contents",
                "hr + hr": {
                  display: "none",
                },
              }}
            >
              {sections.map(({ title, component: Component, fields }, idx) => (
                <Fragment key={title}>
                  <Component
                    key={title}
                    form={form}
                    onSetForm={setForm}
                    onAddToForm={addToForm}
                    fields={fields}
                    terms={rep?.terms}
                    marketingContentfulTerms={title === "Terms and Conditions" ? marketingOptInContent?.fields : undefined}
                    isKorea={isKorea}
                  />
                  {idx !== sections.length - 1 && (
                    <Divider sx={{ width: "100%" }} />
                  )}
                </Fragment>
              ))}
              <Divider sx={{ width: "100%" }} />
            </Box>
            <Stack gap={2} className="form-max" alignItems="center">
              <Stack gap={1} className="form-max" alignItems="center">
                <span>
                  <Button
                    disabled={!isVerified}
                    size="large"
                    onClick={saveValidation}
                  >
                    {inputModelArray?.[3]?.fields?.title}
                  </Button>
                </span>
                {!isVerified && (
                  <Typography
                    variant="body1"
                    align="center"
                    color="neutralCool.main"
                  >
                    {inputModelArray?.[3]?.fields?.item1}
                  </Typography>
                )}
              </Stack>
              {saveApiIsLoading && (
                <CircularProgress />
              )}
              {(saveApiError && !saveApiIsLoading) && (
                <Alert
                severity="error"
                variant="standard"
                className="alert"
                >
                  <Grid container gap={1} direction="column">
                    <Typography align="left" variant="body2">
                      { inputModelArray?.[4]?.fields?.title }
                    </Typography>
                    {saveApiError >= 3 && (
                      <Typography align="left" variant="subhead1">
                        <RichText text={ inputModelArray?.[4]?.fields?.richText } />
                      </Typography>
                    )}
                    {saveApiErrorCode && (
                      <Typography align="left" variant="label2">
                        {inputModelArray?.[4]?.fields?.item1} {saveApiErrorCode}
                      </Typography>
                    )}
                  </Grid>
                </Alert>
              )}

            </Stack>
          </Stack>
        </Paper>
      </Container>
    </div>
  );
};

const VerificationPage = () => {
  const { search } = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(search.replace(/&amp;/g, "&")),
    [search]
  );
  const guid = searchParams.get("guid");
  const checkterms = searchParams.get("checkterms");
  const { headTagModelRef } = useContentfulContext({ entryPoint: "fields" });
  const {
    state: { rep, isLoading, languages : stateLanguages, locale },
  } = useApp();
  const { dispatch } = useApp();
  const [contentfulIsLoading, setContentfulIsLoading] = useState(true);
  const marketingOptInContent = useContentful(locale ? CONTENTFUL.marketingOptIn : null);

  useEffect(() => {
    const isReady = () => {
      if (marketingOptInContent) {
        setContentfulIsLoading(false);
      }
    }

    isReady();
  }, [marketingOptInContent]);

  useEffect(() => {
    const validateGuid = async () => {
      try {
        const res = await get(
          `/api/profile/autoresponder-details?requestedTerms=${
            checkterms === "1"
          }&sessionIdentifier=${guid}`
        );

        // Get Proper Languages before updating rep so that default locale is found in the languages array
        const { BillCountry } = res
        const langRes = await languageApi.getAllByCountry({ country: BillCountry })
        if (langRes && langRes.length > 0) {
          dispatch({ type: 'updateLanguages', languages: [...langRes] })
        }

        // Update the rep
        dispatch({
          type: "updateRep",
          rep: res,
        });

      } catch (e) {
        console.log(e);
      }
    };
    if (guid && checkterms) validateGuid();
  }, [guid, checkterms]);

  return (
    <>
      {isLoading && (
        <Grid
          sx={{
            minHeight: "100vh",
            width: "100vw",
          }}
          container
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      )}
      <Stack
        gap={3}
        alignItems="center"
        py={10}
        bgcolor="neutralCool.lightest"
        minHeight="100vh"
        width="100vw"
      >
        <SiteNavBar data={headTagModelRef} hideNav />
        <ContentfulProvider
          id="30tiQgbHuWJjyEPH6mx80G" // Profile - Korea Verification Page
        >
          {!isLoading && !contentfulIsLoading && <Verification marketingOptInContent={marketingOptInContent}/>}
        </ContentfulProvider>
      </Stack>
    </>
  );
};

export default VerificationPage;
