export default function replaceLabelPlaceholders(label, value) {
  const match = label?.match(/{{\w+}}/g)?.[0] // Regex to find all occurrences of placeholders like {{something}}

  let newLabel = label

  if (match) {
      newLabel = label.replace(match, value); // Replace the placeholder with the data from the form
    } else {
      newLabel = label.replace(" to " + match, "");
    }
    
  return newLabel;
}

export function getLabelName(label) {
  const match = label?.match(/{{\w+}}/g)?.[0]?.replace(/[{}]/g, "");

  return match;
}
