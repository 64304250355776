import { memo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes as ReactRoutes,
  Navigate,
} from "react-router-dom";
import GuidAuth from "components/auth/GuidAuth";
import HomePage from "pages/index";
import Verification from "pages/VerificationPage";
import NiceCallback from "pages/NiceCallback";
import { useApp } from "contexts/AppContext";
import { useQueryParams } from "hooks/useQueryParams";
import { getInitialStateByRef } from "helpers/nav";
import { ContentfulProvider } from "hooks/useContentful";
import { AppContextProvider } from "contexts/AppContext";
import { CONTENTFUL } from "constants/contentful";

export default memo(() => {
  const { search } = window.location;
  const {
    state: {
      rep: { country },
    },
  } = useApp();
  const { params } = useQueryParams();

  // Check to see where they are being redirected from.
  const ref = params.get("ref");
  const fields = params.get("fields");
  const checkterms = params.get("checkterms");
  const artemplateid = params.get("artemplateid");

  const getRoute = () => {
    if (fields | checkterms | artemplateid) {
      return `/verification${search}`;
    }
    return `/profile/${getInitialStateByRef({
      ref,
      country,
    })}${search || ""}`;
  };

  return (
    <ContentfulProvider id={CONTENTFUL[country] || CONTENTFUL.DEFAULT}>
      <Router>
        <ReactRoutes>
          <Route 
            exact 
            path="/verification" 
            element={
              <AppContextProvider>
                <Verification />
              </AppContextProvider>
            }
          />
          <Route exact path="/nice-response" element={<NiceCallback />} />
          <Route exact path="/" element={
            <GuidAuth>
              <Navigate to={getRoute()} />
            </GuidAuth>
          } />
          <Route exact path="/profile/:pageID" element={
            <GuidAuth>
              <HomePage />
            </GuidAuth>
          } />
        </ReactRoutes>
      </Router>
    </ContentfulProvider>
  );
});
